import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { PatientObject } from "./PatientObject";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CentralTriageFormService {
  public baseUrl = environment.apiEndpoint;
  public url = "encounter";
  constructor(private http: HttpClient) {}
  specificIdEmitter = new BehaviorSubject<PatientObject>(
    new PatientObject("start", "start", false)
  );
  // specificIdEmitter = new BehaviorSubject<string>('start');

  getPatientData(): Observable<any> {
    return this.http.get(this.baseUrl + this.url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // triage:String='RED',  &triage=${triage}
  getPatientDataConditionally(
    startDate: String,
    endDate: String
  ): Observable<any> {
    // console.log("getPatientDataConditionally: " + startDate + " - " + endDate);

    const url = `${this.baseUrl + this.url}?startDate=${startDate}`;
    // console.log(url);
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
