import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  callbackUrl = '/covid-form';
  constructor(private auth: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean|UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.auth.login(state.url);
        } else {
          if (!this.checkRoles(next.data.roles)) {
            this.router.navigate([this.callbackUrl]);
          }
        }
      })
    );
  }

  checkRoles(authorized_roles) {
    if (!authorized_roles || !authorized_roles.length) {
      return true;
    }
    if (localStorage.getItem('roles')) {
      for (let r of JSON.parse(localStorage.getItem('roles'))) {
        if (authorized_roles.includes(r)) {
          return true;
        }
      }
    }
    return false;
  }

}