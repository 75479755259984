import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  asylumCaseNumber = '123456';
  //old url public baseUrl = 'https://fontida.herokuapp.com/api/v1/covid/analytics/'; 
  public baseUrl = 'https://covid-api-test.frontidarecords.org/api/v1/covid/analytics/';
  public addUrl;  

  constructor(private http: HttpClient, private datePipe: DatePipe) { }


  getEvaluationsBySection(): Observable<any> {
    var date = new Date();
    var today = this.datePipe.transform(date,"yyyy-MM-dd");
    var tomorrow = this.datePipe.transform(date.setDate(date.getDate() + 1),"yyyy-MM-dd");    
    this.addUrl = `evaluations-by-section`;
    var startDateString = "?startDate="+today+"&endDate="+tomorrow;    
    return (this.http.get<any>(this.baseUrl+this.addUrl+startDateString));    
  }

  getEvaluationsReportedOverTime(timeline): Observable<any> {
    var current = new Date();    
    this.addUrl = `evaluations-reported-over-time`;
    if(timeline=="week"){               
      var monday = moment().startOf('week').toDate();
      var sunday   = moment().endOf('week').toDate();
      console.log(monday+" "+sunday);
      monday.setHours(0,0,0,0);
      sunday.setHours(0,0,0,0);
      var startDateString = "?startDate="+this.datePipe.transform(monday,"yyyy-MM-dd")+"&endDate="+this.datePipe.transform(sunday,"yyyy-MM-dd");;      
    }
    else if(timeline=="month"){           
      var firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
      var lastDay = new Date(current.getFullYear(), current.getMonth()+1, 0);
      firstDay.setHours(0,0,0,0);
      lastDay.setHours(0,0,0,0);            
      var startDateString = "?startDate="+this.datePipe.transform(firstDay,"yyyy-MM-dd")+"&endDate="+this.datePipe.transform(lastDay,"yyyy-MM-dd");;
    }
    else{
      var firstDay = new Date(current.getFullYear(), 0, 1);
      var lastDay = new Date(current.getFullYear(), 11, 31);
      firstDay.setHours(0,0,0,0);
      lastDay.setHours(0,0,0,0);
      var startDateString = "?startDate="+this.datePipe.transform(firstDay,"yyyy-MM-dd")+"&endDate="+this.datePipe.transform(lastDay,"yyyy-MM-dd");;
    }
    return (this.http.get<any>(this.baseUrl+this.addUrl+startDateString));    
  }

  getDemographics(triage){
    this.addUrl = `demographic-details`;
    if(triage=="all")  
      return (this.http.get<any>(this.baseUrl+this.addUrl));
    else
      return (this.http.get<any>(this.baseUrl+this.addUrl+"?triage="+triage.toUpperCase()));
  }
  
  getComorbidities(start, end, triage){
    this.addUrl = `comorbidity`;
    var startDateString = "?startDate="+start+"&endDate="+end; 
    if(triage=="all")   
      return (this.http.get<any>(this.baseUrl+this.addUrl+startDateString));
    else
      return (this.http.get<any>(this.baseUrl+this.addUrl+startDateString+"&triage="+triage.toUpperCase()));
  }

 
}
