import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { AppConfig } from '../../app.config';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LogInComponent implements OnInit {
    constructor(public auth: AuthService) { }

    ngOnInit() {
        this.auth.login(AppConfig.CALLBACK_URL);
    }
}
