import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Router } from '../../../../node_modules/@angular/router';


@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
    isLoading = false;
    constructor(private router: Router, private nav: HeaderService) { 
        this.nav.hide();
    }

    ngOnInit() { }

    agreeClicked() {
        this.router.navigate(['/covid-form']);
        this.nav.show();
    }
}
