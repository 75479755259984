import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Patient } from "./patient";
import { map, shareReplay } from "rxjs/operators";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CovidFormService {
  public baseUrl = environment.apiEndpoint;
  public encounterUrl = `encounter`;

  constructor(private http: HttpClient) {}
  getIssues(): Observable<Patient[]> {
    return this.http.get<Patient[]>(this.baseUrl + this.encounterUrl);
  }

  getIssueById(id): Observable<Patient[]> {
    // return (this.http.get<Patient[]>(this.baseUrl+this.encounterUrl+`?asylumCaseNumber=${id}`));z
    return this.http.get<Patient[]>(
      this.baseUrl + this.encounterUrl + `/${id}`
    );
  }

  postIssues(patient): Observable<any> {
    // console.log(patient);
    const body = JSON.stringify(patient);
    const header = {
      "Content-Type": "application/json",
    };
    // console.log("from service "+body);
    return this.http.post(this.baseUrl + this.encounterUrl, body, {
      headers: header,
    });
  }

  patchIssues(patient, id): Observable<any> {
    // console.log("id in service "+id);
    patient.encounter.id = id;
    // console.log(patient)
    const body = JSON.stringify(patient);
    const header = {
      "Content-Type": "application/json",
    };
    return this.http.patch(this.baseUrl + this.encounterUrl, body, {
      headers: header,
    });
  }

  getEncounters(asylumID): Observable<any> {
    return this.http.get<Patient[]>(
      this.baseUrl + `patient/search?id=${asylumID}`
    );
  }
  getPatient(asylumID): Observable<any> {
    return this.http.get<Patient[]>(this.baseUrl + `patient?id=${asylumID}`);
  }
}
