import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogInComponent } from './shared/login/login.component';
import { CovidFormComponent } from './Components/covid-form/covid-form.component';

import {CentralTriageFormComponent} from './Components/central-triage-form/central-triage-form.component';
import {IndividualPatientFormComponent} from './Components/individual-patient-form/individual-patient-form.component';
import { ReportsComponent } from './Components/reports/reports.component';
import { AuthGuard } from './auth/auth.guard';
import { TermsAndConditionsComponent } from './Components/terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [
  { path: '',
    component: LogInComponent,
    pathMatch: 'full'
  },
  { path: 'patient-data',
    component: CentralTriageFormComponent,
    canActivate: [AuthGuard],
    data: {roles: ['CAMP_MANAGER', 'PROVIDER']}
  },
  { path: 'covid-form',
    component: CovidFormComponent,
    canActivate: [AuthGuard],
    data: {roles: []}
  },
  // { path: 'individualForm',
  //   component: IndividualPatientFormComponent,
  //   canActivate: [AuthGuard]
  // },
  // { path: 'reports',
  //   component: ReportsComponent,
  //   canActivate: [AuthGuard],
  //   data: {roles: ['CAMP_MANAGER', 'EODY']}
  // },
  { path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard],
    data: {roles: []}
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
