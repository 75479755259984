export const auth_dev = {
  auth: {
    domain: "auth-us.frontidarecords.org",
    clientId: "KhOWg4e3gQ70q4U58ONjWhyHOZfZEQXN",
    audience: "https://triage-api.frontidarecords.org",
  },
};
export const auth_prod = {
  auth: {
    domain: "auth-eu.frontidarecords.org",
    clientId: "78ymG7a4qcbrhs8RiWJhwkbayMbj2Rbt",
    audience: "https://triage-api.frontidarecords.org",
  },
};
