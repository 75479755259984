// External packages import
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';

// Component import
import { AppComponent } from './app.component';
import { CovidFormComponent } from './Components/covid-form/covid-form.component';
import { CentralTriageFormComponent } from './Components/central-triage-form/central-triage-form.component';
import { IndividualPatientFormComponent } from './Components/individual-patient-form/individual-patient-form.component';
import { ReportsComponent } from './Components/reports/reports.component';
import { HeaderComponent } from './shared/header/header.component';
import { LogInComponent } from './shared/login/login.component';
import { SpinnerForLoadingComponent } from './shared/spinner-for-loading/spinner-for-loading.component';

// Files import
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material-module';
import { AuthInterceptor } from './services/http.interceptor';
import { TermsAndConditionsComponent } from './Components/terms-and-conditions/terms-and-conditions.component';
import { HeaderService } from './services/header.service';

import { GtagModule } from 'angular-gtag';

// import { RouteReuseStrategy } from '@angular/router';
// import { AppRoutingCache } from './app-routing.cache';

@NgModule({
  declarations: [
    AppComponent,
    CovidFormComponent,
    LogInComponent,
    CentralTriageFormComponent,
    IndividualPatientFormComponent,
    HeaderComponent,
    SpinnerForLoadingComponent,
    ReportsComponent,
    TermsAndConditionsComponent
  ],

  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    ToastrModule.forRoot(),
    ChartsModule,
    GtagModule.forRoot({ trackingId: 'UA-177754543-1', trackPageviews: true })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,  useClass: AuthInterceptor, multi: true },
    //{ provide: RouteReuseStrategy, useClass: AppRoutingCache },
    HeaderService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
