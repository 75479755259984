import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { CovidFormService } from "./covid-form.service";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  Form,
} from "@angular/forms";
import { Patient, CoMorbidities } from "./patient";
import { Subscription } from "rxjs";
import { PatientObject } from "../../services/PatientObject";
import { CentralTriageFormService } from "../../services/central-triage-form.service";
import { formatDate } from "@angular/common";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Gtag } from "angular-gtag";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-covid-form",
  templateUrl: "./covid-form.component.html",
  styleUrls: ["./covid-form.component.css"],
})
export class CovidFormComponent implements OnInit, OnDestroy {
  public searchEncounters: Case[] = [];
  public patientDemographics: PatientDemographic;
  optionsDisplayed: Boolean = false;
  optionClicked: Boolean;
  noResults: Boolean;
  today: Number = Date.now();
  patientInfo;
  asylumSearch: FormControl;
  covidForm: FormGroup;
  firstName: FormControl;
  caseNumber: FormControl;
  papyaNumber: FormControl;
  dob: FormControl;
  camp: FormControl;
  triage: FormControl;
  covidSusp: FormControl;
  covidConfirmed: FormControl;
  lastName: FormControl;
  gender: FormControl;
  country: FormControl;
  language: FormControl;
  section: FormControl;
  block: FormControl;
  isobox: FormControl;
  tent: FormControl;
  otherLocation: FormControl;
  consultationType: FormControl;
  temperature: FormControl;
  rr: FormControl;
  bp: FormControl;
  time: FormControl;
  hr: FormControl;
  spo2: FormControl;
  consultantFirstName: FormControl;
  consultantLastName: FormControl;
  none: FormControl;
  chronicCardiacDisease: FormControl;
  hypertension: FormControl;
  chronicPulmonaryDisease: FormControl;
  asthma: FormControl;
  tb: FormControl;
  kidneyDisease: FormControl;
  liverDisease: FormControl;
  chronicNeurologicalDisorder: FormControl;
  malignancy: FormControl;
  chronicHematologicDisease: FormControl;
  hivAids: FormControl;
  obesity: FormControl;
  diabetes: FormControl;
  postPartum: FormControl;
  postPartumTime: FormControl;
  pregnant: FormControl;
  pregnantWeeks: FormControl;
  rheumatologicalDisorder: FormControl;
  dementia: FormControl;
  malnutrition: FormControl;
  smokingCurrently: FormControl;
  smokingFormer: FormControl;
  allergies: FormControl;
  allergiesSpecified: FormControl;
  other: FormControl;
  otherSpecified: FormControl;
  currentMedication: FormControl;
  reasonForVisit: FormControl;
  diagnosis: FormControl;
  confirm: FormControl;

  // newly added Features
  cough: FormControl;
  fever: FormControl;
  difficultyBreathing: FormControl;
  fatigue: FormControl;
  diarrhea: FormControl;
  lossOfTasteAndSmell: FormControl;
  soreThroat: FormControl;
  headache: FormControl;
  nauseaOrVomiting: FormControl;
  musclePain: FormControl;
  dateOfOnsetOfSymptoms: FormControl;

  closeContactWithConfirmedCase: FormControl;
  dateOfSuspectedExposure: FormControl;
  locationOfSuspectedExposure: FormControl;
  numberOfPeopleInSameShelter: FormControl;

  //bottom section of form
  additionalComments: FormControl;
  nextScheduledVisit: FormControl;
  referralTo: FormControl;
  immediateReferralToHospital: FormControl;

  constructor(
    private toastr: ToastrService,
    private issueService: CovidFormService,
    private formBuilder: FormBuilder,
    private triageFromService: CentralTriageFormService,
    private router: Router,
    private el: ElementRef,
    public dialog: MatDialog,
    gtag: Gtag
  ) {
    gtag.pageview({
      page_title: "Create a new form",
      page_path: "/covid-form",
    });
  }

  private userIDSub: Subscription;
  private userId: string;
  private flag: boolean;
  private id: string;
  private patient: Patient;
  date;

  ngOnInit(): void {
    this.updateClock();
    this.firstName = new FormControl("", Validators.required);
    this.caseNumber = new FormControl("", Validators.required);
    this.dob = new FormControl("", Validators.required);
    this.camp = new FormControl("Kara Tepe 2", Validators.required);
    this.triage = new FormControl("", Validators.required);
    this.papyaNumber = new FormControl();
    this.covidSusp = new FormControl();
    this.asylumSearch = new FormControl("");
    this.lastName = new FormControl();
    this.covidConfirmed = new FormControl();
    this.gender = new FormControl();
    this.country = new FormControl();
    this.language = new FormControl();
    this.section = new FormControl();
    this.block = new FormControl();
    this.isobox = new FormControl();
    this.tent = new FormControl();
    this.otherLocation = new FormControl();
    this.consultationType = new FormControl();
    this.temperature = new FormControl();
    this.rr = new FormControl();
    this.bp = new FormControl();
    this.time = new FormControl();
    this.hr = new FormControl();
    this.spo2 = new FormControl();
    this.consultantFirstName = new FormControl();
    this.consultantLastName = new FormControl();
    this.none = new FormControl();
    this.chronicCardiacDisease = new FormControl();
    this.hypertension = new FormControl();
    this.chronicPulmonaryDisease = new FormControl();
    this.asthma = new FormControl();
    this.tb = new FormControl();
    this.kidneyDisease = new FormControl();
    this.liverDisease = new FormControl();
    this.chronicNeurologicalDisorder = new FormControl();
    this.malignancy = new FormControl();
    this.chronicHematologicDisease = new FormControl();
    this.hivAids = new FormControl();
    this.obesity = new FormControl();
    this.diabetes = new FormControl();
    this.postPartum = new FormControl();
    this.postPartumTime = new FormControl();
    this.pregnant = new FormControl();
    this.pregnantWeeks = new FormControl();
    this.rheumatologicalDisorder = new FormControl();
    this.dementia = new FormControl();
    this.malnutrition = new FormControl();
    this.smokingCurrently = new FormControl();
    this.smokingFormer = new FormControl();
    this.allergies = new FormControl();
    this.allergiesSpecified = new FormControl();
    this.other = new FormControl();
    this.otherSpecified = new FormControl();
    this.currentMedication = new FormControl();
    this.diagnosis = new FormControl();
    this.reasonForVisit = new FormControl();
    this.confirm = new FormControl();
    // newly added Features
    this.cough = new FormControl();
    this.fever = new FormControl();
    this.difficultyBreathing = new FormControl();
    this.fatigue = new FormControl();
    this.diarrhea = new FormControl();
    this.lossOfTasteAndSmell = new FormControl();
    this.soreThroat = new FormControl();
    this.headache = new FormControl();
    this.nauseaOrVomiting = new FormControl();
    this.musclePain = new FormControl();
    this.dateOfOnsetOfSymptoms = new FormControl();

    this.closeContactWithConfirmedCase = new FormControl();
    this.dateOfSuspectedExposure = new FormControl();
    this.locationOfSuspectedExposure = new FormControl();
    this.numberOfPeopleInSameShelter = new FormControl();

    this.additionalComments = new FormControl();
    this.nextScheduledVisit = new FormControl();
    this.referralTo = new FormControl();
    this.immediateReferralToHospital = new FormControl();

    this.covidForm = this.formBuilder.group({
      // personal info of patient
      caseNumber: this.caseNumber,
      papyaNumber: this.papyaNumber,
      firstName: this.firstName,
      lastName: this.lastName,
      dob: this.dob,
      country: this.country,
      language: this.language,

      // address location
      section: this.section,
      block: this.block,
      isobox: this.isobox,
      tent: this.tent,
      otherLocation: this.otherLocation,
      // end of identifiable infomation

      // start of non identifiable information
      // patient info
      camp: this.camp, // the location here means camp's location
      gender: this.gender,

      // triage Evaluation
      triage: this.triage, // options(yellow, red, green) === triage
      consultationType: this.consultationType,

      // covid suspect or covidConfirmed case
      covidSusp: this.covidSusp,
      covidConfirmed: this.covidConfirmed,

      // vital sings
      temperature: this.temperature,
      rr: this.rr,
      bp: this.bp,
      time: this.time,
      hr: this.hr,
      spo2: this.spo2,

      // covid symptoms
      cough: this.cough,
      fever: this.fever,
      difficultyBreathing: this.difficultyBreathing,
      fatigue: this.fatigue,
      diarrhea: this.diarrhea,
      lossOfTasteAndSmell: this.lossOfTasteAndSmell,
      soreThroat: this.soreThroat,
      headache: this.headache,
      nauseaOrVomiting: this.nauseaOrVomiting,
      musclePain: this.musclePain,
      dateOfOnsetOfSymptoms: this.dateOfOnsetOfSymptoms,

      // exposure
      closeContactWithConfirmedCase: this.closeContactWithConfirmedCase,
      dateOfSuspectedExposure: this.dateOfSuspectedExposure,
      locationOfSuspectedExposure: this.locationOfSuspectedExposure,
      numberOfPeopleInSameShelter: this.numberOfPeopleInSameShelter,

      // consultation
      consultantFirstName: this.consultantFirstName,
      consultantLastName: this.consultantLastName,

      // 'none': this.none,
      // The following is about Morbidities
      none: this.none,
      chronicCardiacDisease: this.chronicCardiacDisease,
      hypertension: this.hypertension,
      chronicPulmonaryDisease: this.chronicPulmonaryDisease,
      asthma: this.asthma,
      tb: this.tb,
      kidneyDisease: this.kidneyDisease,
      liverDisease: this.liverDisease,
      chronicNeurologicalDisorder: this.chronicNeurologicalDisorder,
      malignancy: this.malignancy,
      chronicHematologicDisease: this.chronicHematologicDisease,
      hivAids: this.hivAids,
      obesity: this.obesity,
      diabetes: this.diabetes,
      postPartum: this.postPartum,
      postPartumTime: this.postPartumTime,
      pregnant: this.pregnant,
      pregnantWeeks: this.pregnantWeeks,
      rheumatologicalDisorder: this.rheumatologicalDisorder,
      dementia: this.dementia,
      malnutrition: this.malnutrition,
      smokingCurrently: this.smokingCurrently,
      smokingFormer: this.smokingFormer,
      allergies: this.allergies,
      allergiesSpecified: this.allergiesSpecified,
      other: this.other,
      otherSpecified: this.otherSpecified,
      currentMedication: this.currentMedication,
      reasonForVisit: this.reasonForVisit,
      diagnosis: this.diagnosis,
      confirm: this.confirm,

      additionalComments: this.additionalComments,
      nextScheduledVisit: this.nextScheduledVisit,
      referralTo: this.referralTo,
      immediateReferralToHospital: this.immediateReferralToHospital,
    });

    this.userIDSub = this.triageFromService.specificIdEmitter.subscribe(
      (patient) => {
        this.userId = patient.asylum;
        this.flag = patient.flag;
        this.id = patient.id;
        if (this.flag == true) {
          // this.flag=false;
          this.onView(this.id);
        }
      }
    );
  }

  prepopulateFromSearch(caseEncounter): void {
    this.optionsDisplayed = false;
    this.optionClicked = true;
    this.issueService
      .getPatient(caseEncounter.caseNumber)
      .subscribe((data: any) => {
        this.patientDemographics = data.patient;
        this.covidForm
          .get("firstName")
          .setValue(this.patientDemographics.firstName);
        this.covidForm
          .get("lastName")
          .setValue(this.patientDemographics.lastName);
        this.covidForm
          .get("caseNumber")
          .setValue(this.patientDemographics.caseNumber);
        this.date = formatDate(
          this.patientDemographics.dob,
          "yyyy-MM-dd",
          "en-US"
        );
        this.covidForm.get("dob").setValue(this.date);
        this.covidForm.get("camp").setValue(this.patientDemographics.camp); // "camp" here is "lcoation" in html
        this.covidForm
          .get("language")
          .setValue(this.patientDemographics.language);
        this.covidForm
          .get("country")
          .setValue(this.patientDemographics.country);
        this.covidForm.get("gender").setValue(this.patientDemographics.gender);
      });
  }

  prepopulateWithAsylumID(): void {
    let asylumID = (<HTMLInputElement>document.getElementById("asylumSearch"))
      .value;
    this.optionsDisplayed = false;
    this.noResults = null;
    this.covidForm.get("caseNumber").setValue(asylumID);
    this.optionClicked = true;
  }

  ngOnDestroy(): void {
    const patientObject: PatientObject = {
      asylum: "close",
      id: "close",
      flag: false,
    };
    this.triageFromService.specificIdEmitter.next(patientObject);
    this.userIDSub.unsubscribe();
  }

  //This is currently not being used, but it checks to see if an asylum number has been taken yet or not.
  checkAsylumNumber() {
    const asylumNumber = this.covidForm.value.caseNumber;
    if (asylumNumber != null) {
      this.issueService.getPatient(asylumNumber).subscribe(
        (data: any) => {
          this.toastr.warning(
            "This asylum number has been taken, please choose another one!"
          );
        },
        (err) => {
          this.toastr.success("This asylum number is available!");
        }
      );
    }
  }

  searchEncounter() {
    this.optionsDisplayed = true;
    this.optionClicked = null;
    this.noResults = null;
    let asylum = (<HTMLInputElement>(
      document.getElementById("asylumSearch")
    )).value
      .toString()
      .trim();
    if (!asylum) return; //dont ping server without user input

    this.issueService.getEncounters(asylum).subscribe((data: any) => {
      this.searchEncounters = [];
      let i = 0;
      while (i < data.encounter.length) {
        this.searchEncounters[i] = data.encounter[i];
        i++;
        // if (i > 5) break;    //This limit is not needed. API's job to handle return size, not front end.
      }

      //Sort the results so the SHORTEST case number popus up first. This means if they search
      //for '2345', then 2345 will show before 123456, other any other casenumbers thats have the substring
      this.searchEncounters.sort((a, b) => {
        return a.caseNumber.length >= b.caseNumber.length ? 1 : -1;
      });

      if (this.searchEncounters.length == 0) this.noResults = true;
    });
  }

  onView(userId) {
    this.issueService.getIssueById(userId).subscribe(
      (data: any) => {
        this.patientInfo = data.encounter;
        this.setValues();
      },
      (err) => {
        console.log("Unable to get data");
      }
    );
  }

  // This function scrolls to the first instance of an erroneous form field
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth",
    });
  }

  //caluclates the distance of where the screen should scroll to
  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  onSubmit() {
    console.log("submitting!");

    const coMorbidities: CoMorbidities = {
      none: this.covidForm.value.none || false,
      chronicCardiacDisease: this.covidForm.value.chronicCardiacDisease,
      hypertension: this.covidForm.value.hypertension,
      chronicPulmonaryDisease: this.covidForm.value.chronicPulmonaryDisease,
      asthma: this.covidForm.value.asthma,
      tb: this.covidForm.value.tb,
      kidneyDisease: this.covidForm.value.kidneyDisease,
      liverDisease: this.covidForm.value.liverDisease,
      chronicNeurologicalDisorder: this.covidForm.value
        .chronicNeurologicalDisorder,
      malignancy: this.covidForm.value.malignancy,
      chronicHematologicDisease: this.covidForm.value.chronicHematologicDisease,
      hivAids: this.covidForm.value.hivAids,
      obesity: this.covidForm.value.obesity,
      diabetes: this.covidForm.value.diabetes,
      postPartum: this.covidForm.value.postPartum,
      postPartumTime: this.covidForm.value.postPartumTime,
      pregnant: this.covidForm.value.pregnant,
      pregnantWeeks: this.covidForm.value.pregnantWeeks,
      rheumatologicalDisorder: this.covidForm.value.rheumatologicalDisorder,
      dementia: this.covidForm.value.dementia,
      malnutrition: this.covidForm.value.malnutrition,
      smokingCurrently: this.covidForm.value.smokingCurrently,
      smokingFormer: this.covidForm.value.smokingFormer,
      allergies: this.covidForm.value.allergies,
      allergiesSpecified: this.covidForm.value.allergiesSpecified,
      other: this.covidForm.value.other,
      otherSpecified: this.covidForm.value.otherSpecified,
    };

    const patient: Patient = {
      encounter: {
        //timestamp of the interview
        formDateTime: Date.now(),

        // personal info of patient
        caseNumber: this.covidForm.value.caseNumber,
        papyaNumber: this.covidForm.value.papyaNumber,
        firstName: this.covidForm.value.firstName,
        lastName: this.covidForm.value.lastName,
        dob: this.covidForm.value.dob,
        camp: this.covidForm.value.camp, // the location here means camp's location
        triage: this.covidForm.value.triage, // options(yellow, red, green) === triage

        country: this.covidForm.value.country,
        language: this.covidForm.value.language,

        // address location
        section: this.covidForm.value.section,
        block: this.covidForm.value.block,
        isobox: this.covidForm.value.isobox,
        tent: this.covidForm.value.tent,
        otherLocation: this.covidForm.value.otherLocation,
        // // end of identifiable infomation

        //   // start of non identifiable information
        //   // patient info
        gender: this.covidForm.value.gender,

        // // triage Evaluation
        consultationType: this.covidForm.value.consultationType,

        // // covid suspect or confirmed case
        covidSusp: this.covidForm.value.covidSusp,
        covidConfirmed: this.covidForm.value.covidConfirmed,

        // // vital sings
        temperature: this.covidForm.value.temperature,
        rr: this.covidForm.value.rr,
        bp: this.covidForm.value.bp,
        time: this.covidForm.value.time,
        hr: this.covidForm.value.hr,
        spo2: this.covidForm.value.spo2,

        // // covid symptoms
        cough: this.covidForm.value.cough,
        fever: this.covidForm.value.fever,
        difficultyBreathing: this.covidForm.value.difficultyBreathing,
        fatigue: this.covidForm.value.fatigue,
        diarrhea: this.covidForm.value.diarrhea,
        lossOfTasteAndSmell: this.covidForm.value.lossOfTasteAndSmell,
        soreThroat: this.covidForm.value.soreThroat,
        headache: this.covidForm.value.headache,
        nauseaOrVomiting: this.covidForm.value.nauseaOrVomiting,
        musclePain: this.covidForm.value.musclePain,
        dateOfOnsetOfSymptoms: this.covidForm.value.dateOfOnsetOfSymptoms,

        // // exposure
        closeContactWithConfirmedCase: this.covidForm.value
          .closeContactWithConfirmedCase,
        dateOfSuspectedExposure: this.covidForm.value.dateOfSuspectedExposure,
        locationOfSuspectedExposure: this.covidForm.value
          .locationOfSuspectedExposure,
        numberOfPeopleInSameShelter: this.covidForm.value
          .numberOfPeopleInSameShelter,

        // // consultation
        consultantFirstName: this.covidForm.value.consultantFirstName,
        consultantLastName: this.covidForm.value.consultantLastName,
        ...coMorbidities,
        // coMorbidities: coMorbidities,
        currentMedication: this.covidForm.value.currentMedication,
        reasonForVisit: this.covidForm.value.reasonForVisit,
        diagnosis: this.covidForm.value.diagnosis,

        additionalComments: this.covidForm.value.additionalComments,
        nextScheduledVisit: this.covidForm.value.nextScheduledVisit,
        referralTo: this.covidForm.value.referralTo,
        immediateReferralToHospital: this.covidForm.value
          .immediateReferralToHospital,
      },
    };

    if (this.covidForm.valid && this.flag == false) {
      const asylumNumber = this.covidForm.value.caseNumber;
      if (asylumNumber != null) {
        this.issueService.postIssues(patient).subscribe(
          (data) => {
            alert("SUCCESSFUL!");
            this.covidForm.reset();
            // this.covidForm.markAsPristine();
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          },
          (err) => {
            console.log("Unable to post data" + err);
          }
        );
      }
    } else if (this.covidForm.valid && this.flag == true) {
      this.flag = false;

      this.issueService.patchIssues(patient, this.id).subscribe(
        (data) => {
          alert("SUCCESSFUL UPDATE!");
          this.router.navigate(["/patient-data"]);
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        },
        (err) => {
          console.log("Unable to patch data");
        }
      );
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  setValues() {
    this.covidForm.reset();

    console.log("setting values with...");
    console.log(this.patientInfo);

    //setting values of text boxes and text area
    this.covidForm.get("firstName").setValue(this.patientInfo.firstName);
    this.covidForm.get("lastName").setValue(this.patientInfo.lastName);
    this.covidForm.get("caseNumber").setValue(this.patientInfo.caseNumber);
    this.covidForm.get("papyaNumber").setValue(this.patientInfo.papyaNumber);
    this.covidForm.get("camp").setValue(this.patientInfo.camp); // "camp" here is "lcoation" in html
    this.covidForm.get("section").setValue(this.patientInfo.section);
    this.covidForm.get("block").setValue(this.patientInfo.block);
    this.covidForm.get("isobox").setValue(this.patientInfo.isobox);
    this.covidForm.get("tent").setValue(this.patientInfo.tent);
    this.covidForm
      .get("otherLocation")
      .setValue(this.patientInfo.otherLocation);
    this.covidForm.get("temperature").setValue(this.patientInfo.temperature);
    this.covidForm.get("rr").setValue(this.patientInfo.rr);
    this.covidForm.get("bp").setValue(this.patientInfo.bp);
    this.covidForm.get("time").setValue(this.patientInfo.time);
    this.covidForm.get("hr").setValue(this.patientInfo.hr);
    this.covidForm.get("spo2").setValue(this.patientInfo.spo2);
    this.covidForm
      .get("consultantFirstName")
      .setValue(this.patientInfo.consultantFirstName);
    this.covidForm
      .get("consultantLastName")
      .setValue(this.patientInfo.consultantLastName);
    this.covidForm
      .get("postPartumTime")
      .setValue(this.patientInfo.postPartumTime);
    this.covidForm
      .get("pregnantWeeks")
      .setValue(this.patientInfo.pregnantWeeks);
    this.covidForm
      .get("allergiesSpecified")
      .setValue(this.patientInfo.allergiesSpecified);
    this.covidForm
      .get("otherSpecified")
      .setValue(this.patientInfo.otherSpecified);
    this.covidForm
      .get("currentMedication")
      .setValue(this.patientInfo.currentMedication);
    this.covidForm
      .get("reasonForVisit")
      .setValue(this.patientInfo.reasonForVisit);
    this.covidForm.get("diagnosis").setValue(this.patientInfo.diagnosis);
    this.covidForm
      .get("dateOfOnsetOfSymptoms")
      .setValue(this.patientInfo.dateOfOnsetOfSymptoms);
    this.covidForm
      .get("closeContactWithConfirmedCase")
      .setValue(this.patientInfo.closeContactWithConfirmedCase);
    this.covidForm
      .get("dateOfSuspectedExposure")
      .setValue(this.patientInfo.dateOfSuspectedExposure);
    this.covidForm
      .get("locationOfSuspectedExposure")
      .setValue(this.patientInfo.locationOfSuspectedExposure);
    this.covidForm
      .get("numberOfPeopleInSameShelter")
      .setValue(this.patientInfo.numberOfPeopleInSameShelter);
    this.covidForm
      .get("additionalComments")
      .setValue(this.patientInfo.additionalComments);
    this.covidForm
      .get("nextScheduledVisit")
      .setValue(this.patientInfo.nextScheduledVisit);
    this.covidForm.get("referralTo").setValue(this.patientInfo.referralTo);

    //setting values of checkboxes
    this.patientInfo.covidSusp == true
      ? this.covidForm.get("covidSusp").setValue("1")
      : "";
    this.patientInfo.covidConfirmed == true
      ? this.covidForm.get("covidConfirmed").setValue("1")
      : "";

    this.patientInfo.cough == true
      ? this.covidForm.get("cough").setValue("1")
      : "";
    this.patientInfo.fever == true
      ? this.covidForm.get("fever").setValue("1")
      : "";
    this.patientInfo.difficultyBreathing == true
      ? this.covidForm.get("difficultyBreathing").setValue("1")
      : "";
    this.patientInfo.fatigue == true
      ? this.covidForm.get("fatigue").setValue("1")
      : "";
    this.patientInfo.diarrhea == true
      ? this.covidForm.get("diarrhea").setValue("1")
      : "";
    this.patientInfo.lossOfTasteAndSmell == true
      ? this.covidForm.get("lossOfTasteAndSmell").setValue("1")
      : "";
    this.patientInfo.soreThroat == true
      ? this.covidForm.get("soreThroat").setValue("1")
      : "";
    this.patientInfo.headache == true
      ? this.covidForm.get("headache").setValue("1")
      : "";
    this.patientInfo.nauseaOrVomiting == true
      ? this.covidForm.get("nauseaOrVomiting").setValue("1")
      : "";
    this.patientInfo.musclePain == true
      ? this.covidForm.get("musclePain").setValue("1")
      : "";

    this.patientInfo.none == true
      ? this.covidForm.get("none").setValue("1")
      : "";
    this.patientInfo.chronicCardiacDisease == true
      ? this.covidForm.get("chronicCardiacDisease").setValue("1")
      : "";
    this.patientInfo.hypertension == true
      ? this.covidForm.get("hypertension").setValue("1")
      : "";
    this.patientInfo.chronicPulmonaryDisease == true
      ? this.covidForm.get("chronicPulmonaryDisease").setValue("1")
      : "";
    this.patientInfo.asthma == true
      ? this.covidForm.get("asthma").setValue("1")
      : "";
    this.patientInfo.tb == true ? this.covidForm.get("tb").setValue("1") : "";
    this.patientInfo.kidneyDisease == true
      ? this.covidForm.get("kidneyDisease").setValue("1")
      : "";
    this.patientInfo.liverDisease == true
      ? this.covidForm.get("liverDisease").setValue("1")
      : "";

    this.patientInfo.chronicNeurologicalDisorder == true
      ? this.covidForm.get("chronicNeurologicalDisorder").setValue("1")
      : "";
    this.patientInfo.malignancy == true
      ? this.covidForm.get("malignancy").setValue("1")
      : "";
    this.patientInfo.chronicHematologicDisease == true
      ? this.covidForm.get("chronicHematologicDisease").setValue("1")
      : "";
    this.patientInfo.hivAids == true
      ? this.covidForm.get("hivAids").setValue("1")
      : "";
    this.patientInfo.obesity == true
      ? this.covidForm.get("obesity").setValue("1")
      : "";
    this.patientInfo.diabetes == true
      ? this.covidForm.get("diabetes").setValue("1")
      : "";
    this.patientInfo.postPartum == true
      ? this.covidForm.get("postPartum").setValue("1")
      : "";
    this.patientInfo.pregnant == true
      ? this.covidForm.get("pregnant").setValue("1")
      : "";

    this.patientInfo.rheumatologicalDisorder == true
      ? this.covidForm.get("rheumatologicalDisorder").setValue("1")
      : "";
    this.patientInfo.dementia == true
      ? this.covidForm.get("dementia").setValue("1")
      : "";
    this.patientInfo.malnutrition == true
      ? this.covidForm.get("malnutrition").setValue("1")
      : "";
    this.patientInfo.smokingCurrently == true
      ? this.covidForm.get("smokingCurrently").setValue("1")
      : "";
    this.patientInfo.smokingFormer == true
      ? this.covidForm.get("smokingFormer").setValue("1")
      : "";
    this.patientInfo.allergies == true
      ? this.covidForm.get("allergies").setValue("1")
      : "";
    this.patientInfo.other == true
      ? this.covidForm.get("other").setValue("1")
      : "";

    //setting values of select menus
    this.covidForm.get("language").setValue(this.patientInfo.language);
    this.covidForm.get("country").setValue(this.patientInfo.country);

    //setting values of radio buttons
    this.covidForm.get("triage").setValue(this.patientInfo.triage);
    this.covidForm.get("gender").setValue(this.patientInfo.gender);
    this.covidForm
      .get("immediateReferralToHospital")
      .setValue(this.patientInfo.immediateReferralToHospital);

    this.covidForm
      .get("consultationType")
      .setValue(this.patientInfo.consultationType);
    this.covidForm
      .get("closeContactWithConfirmedCase")
      .setValue(
        this.patientInfo.closeContactWithConfirmedCase == null
          ? ""
          : this.patientInfo.closeContactWithConfirmedCase.toString()
      );
    //setting value for date field
    this.date = formatDate(this.patientInfo.dob, "yyyy-MM-dd", "en-US");
    // console.log(this.patientInfo.dob);

    this.covidForm.get("dob").setValue(this.date);
  }

  // update the clock upwards every second
  updateClock() {
    setInterval(() => (this.today = Date.now()), 1000);
  }
}
export interface Case {
  firstName: string;
  lastName: string;
  dob: string;
  caseNumber: string;
}
export interface PatientDemographic {
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  camp: string;
  language: string;
  country: string;
  caseNumber: string;
  papyaNumber: string;
}
