import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ExportToCsv } from "ts-export-to-csv";
import { ToastrService } from "ngx-toastr";
import { MatPaginator } from "@angular/material/paginator";

import { CentralTriageFormService } from "../../services/central-triage-form.service";
import { PatientObject } from "../../services/PatientObject";
import { PersonElement, TimeFrame, Triage } from "./user.model";
import { Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { of } from "rxjs";
import { Gtag } from "angular-gtag";

let PERSON_DATA: PersonElement[] = [];

@Component({
  selector: "app-central-triage-form",
  templateUrl: "./central-triage-form.component.html",
  styleUrls: ["./central-triage-form.component.css"],
})
export class CentralTriageFormComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private toastr: ToastrService,
    private getDataService: CentralTriageFormService,
    private router: Router,
    public auth: AuthService,
    gtag: Gtag
  ) {
    gtag.pageview({
      page_title: "View all forms",
      page_path: "/patient-data",
    });
  }
  // // define initial value
  selectedTimeFrame = "365";
  selectedTriage = "All";

  // define initial value for parameter in backend API calls
  selectedDate: String = this.getLastYear();
  selectedColor: String = "All triage types";

  statusArray = ["red", "yellow", "green"];
  colorMap = [
    { status: "green", color: "#DBF1A1" },
    { status: "yellow", color: "#D4C26A" },
    { status: "red", color: "#D46A6A" },
    { status: "", color: "grey" },
  ];

  timeFrame: TimeFrame[] = [
    { value: 1, viewValue: "Today" },
    { value: 7, viewValue: "The Past Week" },
    { value: 30, viewValue: "The Past Month" },
    { value: 365, viewValue: "The past Year" },
    { value: 10000, viewValue: "All" },
  ];

  triage: Triage[] = [
    { value: "green", viewValue: "Green" },
    { value: "yellow", viewValue: "Yellow" },
    { value: "red", viewValue: "Red" },
    { value: "all", viewValue: "All" },
  ];

  displayedColumns: string[] = [
    "date",
    "asylum",
    "firstName",
    "lastName",
    "triage",
    "id",
  ];
  dataSource = new MatTableDataSource<PersonElement>(PERSON_DATA);

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.initializeData();
    this.sortByDate();
    // this.dataSource.paginator = this.paginator;
  }

  //This function modifies the default sort behavior and tells the table
  //how to properly interpret the date column
  sortByDate(): void {
    //custom sorting: needed to Sort date column as Date objects instead of Strings
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "date": {
          let dateString: String = item.date; //DD-MM-YYYY format
          let dateParts: String[] = dateString.split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject: Date = new Date(
            +dateParts[2],
            +dateParts[1] - 1,
            +dateParts[0]
          );
          return dateObject;
        }
        default:
          return item[property];
      }
    };

    //Sort by the date column
    const sortState: Sort = { active: "date", direction: "desc" };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  initializeData(): void {
    this.dataSource.sort = this.sort;
    this.getDataService.getPatientData().subscribe(
      (data) => {
        this.createObjectForData(data.encounters);
      },
      (err) => {
        // console.log('in the error')
        console.log(err);
      }
    );
  }

  createObjectForData(data: any) {
    PERSON_DATA = [];
    if (data != null) {
      data.filter((element) => {
        const newElement: PersonElement = {
          id: element.id,
          caseNumber: element.caseNumber,
          date: element.createdAt,
          firstName: element.firstName,
          lastName: element.lastName,
          triage: element.triage,
          referralTo: element.referralTo,
        };
        PERSON_DATA.push(newElement);
      });
    }
    this.dataSource = new MatTableDataSource(PERSON_DATA);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getColor(status: string): string {
    if (!this.statusArray.includes(status.toLowerCase())) {
      return "grey";
    }
    return this.colorMap.filter(
      (item) => item.status === status.toLowerCase()
    )[0].color;
  }

  //Shows entries in the table that are all within a certain time frame
  getTimeFrame(data: string) {
    //This creates a current date thats in UTC format.
    var arg = new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000;
    const current: Date = new Date(arg);

    const currentString =
      current.getFullYear() +
      "-" +
      this.appendZero(current.getMonth() + 1) +
      "-" +
      this.appendZero(current.getDate());
    const previous: Date = new Date(
      current.getTime() - parseInt(data, 10) * 24 * 60 * 60 * 1000
    );
    this.selectedDate =
      previous.getFullYear() +
      "-" +
      this.appendZero(previous.getMonth() + 1) +
      "-" +
      this.appendZero(previous.getDate());
    this.getDataService
      .getPatientDataConditionally(this.selectedDate, currentString)
      .subscribe(
        (data) => {
          this.createObjectForData(data.encounters);
          this.getTriage(this.selectedTriage);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getTriage(data: string): void {
    this.selectedTriage = data;
    const filterPersonData: PersonElement[] = [];
    if (data === "red" || data === "yellow" || data === "green") {
      for (const item of PERSON_DATA) {
        // FILTER DATA
        if (item.triage.toLowerCase() === data) {
          filterPersonData.push(item);
        }
      }
      this.dataSource = new MatTableDataSource(filterPersonData);
      this.dataSource.sort = this.sort;
    } else if (data === "all") {
      // GO BACK TO NORMAL
      this.dataSource = new MatTableDataSource(PERSON_DATA);
      this.dataSource.sort = this.sort;
    }
  }

  onClickEditButton(asylum: string, id: string) {
    const patientObject: PatientObject = {
      asylum: asylum,
      id: id,
      flag: true,
    };
    this.getDataService.specificIdEmitter.next(patientObject);
    this.router.navigate(["/covid-form"]);
  }

  convertToCsvForAll(): void {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Central Triage Forms For All Data",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    this.toastr.success(
      "Data has been downloaded, Please check it!",
      "Notification!"
    );
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(PERSON_DATA);
  }

  convertToCsvForToday(): void {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Central Triage Forms For Today's Data",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const current: Date = new Date();
    const requiredTime: number = 24 * 60 * 60 * 1000;
    const filterPersonData: PersonElement[] = [];

    for (const item of PERSON_DATA) {
      // FILTER DATA
      if (current.getTime() - new Date(item.date).getTime() <= requiredTime) {
        filterPersonData.push(item);
      }
    }
    if (filterPersonData.length === 0) {
      this.toastr.warning(
        "Today's data is empty, Cannot download!",
        "Notification!"
      );
    } else {
      this.toastr.success(
        "Data has been downloaded, Please check it!",
        "Notification!"
      );
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(filterPersonData);
    }
  }

  getLastYear(): String {
    const time = new Date(new Date().getTime() - 361 * 24 * 60 * 60 * 1000);
    return (
      time.getFullYear() +
      "-" +
      this.appendZero(time.getMonth() + 1) +
      "-" +
      this.appendZero(time.getDate())
    );
  }

  appendZero(s: any): String {
    return ("00" + s).substr((s + "").length);
  }
}
