import { Component, OnDestroy, OnInit } from '@angular/core';
import { CentralTriageFormService } from '../../services/central-triage-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-individual-patient-form',
  templateUrl: './individual-patient-form.component.html',
  styleUrls: ['./individual-patient-form.component.css']
})
export class IndividualPatientFormComponent implements OnInit, OnDestroy {

  constructor(public triageFromService: CentralTriageFormService) { }

  public userIDSub: Subscription;
  public userId: string;

  ngOnInit(): void {
    this.userIDSub = this.triageFromService.specificIdEmitter.subscribe(
      id => {
        // this.userId = id;
      }
    );
    // id from the central-triage-form page
    console.log(this.userId);
  }
  ngOnDestroy(): void {
    this.userIDSub.unsubscribe();
  }

}
