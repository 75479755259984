import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isReports = false;
  isViewForms = false;
  constructor(public auth: AuthService, public nav: HeaderService) { 
    this.checkRoles();
  }

  ngOnInit() { }

  forceReload() {
    // window.location.reload()
  }

  checkRoles() {
    this.auth.isAuthenticated$.subscribe(user => {
      if (localStorage.getItem('roles')) {
        for (let role of JSON.parse(localStorage.getItem('roles'))) {
          if (role === 'CAMP_MANAGER') {
            this.isReports = true;
            this.isViewForms = true;
          } else if (role === 'PROVIDER') {
            this.isViewForms = true;
          } else if (role === 'EODY') {
            this.isReports = true;
          }
        }
      }
    });
  }

}
