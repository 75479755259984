import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets,ChartType, ChartOptions, Chart } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ReportsService } from './reports.service';
import { BaseChartDirective } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { Gtag } from 'angular-gtag';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})


export class ReportsComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;


  //Plotting Pie Chart for Today's evaluations

  
  public options: any = {
    legend: { position: 'right' }
  }
  public pieChartLabels = ['Red Evaluation', 'Green Evaluation', 'Yellow Evaluation'];
  public pieChartData = [0,0,0];
  public totalSec;
  public pieChartType = 'pie';
  public pieChartColors: Array < any > = [{
    backgroundColor: ['#fc5858', '#19d863', '#fdf57d'],
    borderColor: ['rgba(252, 235, 89, 0.2)', 'rgba(77, 152, 202, 0.2)', 'rgba(241, 107, 119, 0.2)']
  }];

  //getting evaluations from service
  getEvaluationsBySection()
  {    
    this.issueService
          .getEvaluationsBySection()
          .subscribe(
            (data: any) => {           
              if(data.results[0]!=null)                     
                this.pieChartData = [Number(data.results[0].count),
                      Number(data.results[1].count),
                      Number(data.results[2].count)]  
              else
                this.pieChartData = [0,0,0] 
            },
            err => {
              console.log("Unable to get data");
            }
          )                 
  }
//end of pie chart


  //Plotting line chart for Evaluation By Section

  //get evaluations reported over time from service
  getEvaluationsReportedOverTime(timeline)
  {
    this.issueService
          .getEvaluationsReportedOverTime(timeline)
          .subscribe(
            (data: any) => {                                
              //  console.log(data.results);
               this.extractLineChartData(data.results, timeline);                                            
            },
            err => {
              console.log("Unable to get data");
            }
          ) 
  }


  // getWeekNumber(date)
  // {
  //   var current = new Date();
  //   var oneJan = new Date(current.getFullYear(), 0, 1);   
  //   var numberOfDays = Math.floor((current.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000)); 
  //   return Math.ceil((current.getDay() + 1 + numberOfDays) / 7);   
  // }

  //structuring data returned by API 
  extractLineChartData(data, timeline)
  {    
    var red=[], yellow=[], green=[], all=[];
    if(timeline=="week"){  
      red=[0,0,0,0,0,0,0];
      yellow=[0,0,0,0,0,0,0];
      green=[0,0,0,0,0,0,0];
      all=[0,0,0,0,0,0,0];
      data.forEach(element => {
        var ind = new Date(element.day).getDay();
        red[ind] = element.red;
        yellow[ind] = element.yellow;
        green[ind] = element.green;
        all[ind] = element.all;        
      });
      this.lineChartData = [
        { data: red, label: 'Red Evaluation' },
        { data: yellow, label: 'Yellow Evaluation' },
        { data: green, label: 'Green Evaluation' },
        { data: all, label: 'Total # of evaluations' },
      ];
      this.lineChartLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    }
    // else if(timeline=="month"){
    //   red=[0,0,0,0,];
    //   yellow=[0,0,0,0];
    //   green=[0,0,0,0];
    //   all=[0,0,0,0];
    //   data.forEach(element => {
    //   });
    //   this.lineChartData = [
    //     { data: red, label: 'Red Evaluation' },
    //     { data: yellow, label: 'Yellow Evaluation' },
    //     { data: green, label: 'Green Evaluation' },
    //     { data: all, label: 'Total # of evaluations' },
    //   ];
    //   this.lineChartLabels = ['Week 1', 'Week 2', 'Week 3', 'Week 3'];
    // }
    else{
      red =[0,0,0,0,0,0,0,0,0,0,0,0];
      yellow=[0,0,0,0,0,0,0,0,0,0,0,0];
      green=[0,0,0,0,0,0,0,0,0,0,0,0];
      all=[0,0,0,0,0,0,0,0,0,0,0,0];
      data.forEach(element => {       
        var ind = new Date(element.day).getMonth();        
        red[ind] += Number(element.red);
        yellow[ind] += Number(element.yellow);
        green[ind] += Number(element.green);
        all[ind] += Number(element.all);        
      });
      this.lineChartData = [
        { data: red, label: 'Red Evaluation' },
        { data: yellow, label: 'Yellow Evaluation' },
        { data: green, label: 'Green Evaluation' },
        { data: all, label: 'Total # of evaluations' },
      ];
      this.lineChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }
  }


  public lineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Red Evaluation' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Yellow Evaluation' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Green Evaluation' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Total # of evaluations' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartColors: Color[] = [
    {
      borderColor: 'red',
      backgroundColor: 'rgba(255,0,0,0)',
    },
    {
      borderColor: 'yellow',
      backgroundColor: 'rgba(255,0,0,0)',
    },
    {
      borderColor: 'green',
      backgroundColor: 'rgba(255,0,0,0)',
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public lineChartOptions  = {
    responsive: true,
    legend: { position: 'right' },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: '# of people',          
        },   
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
      }   
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        }
      }],
    } 
  }
//end of line chart





//Building table for Demographics

  //fetching data from service API
  getDemographics(triage){
    this.issueService
          .getDemographics(triage)
          .subscribe(
            (data: any) => {   
              //  console.log(data.results);
               this.extractDemographics(data.results, triage);                                          
            },
            err => {
              console.log("Unable to get data");
            }
          ) 
  }  
  
  public demo_data = [];

  //structuring data returned by API
  extractDemographics(data, triage){
    this.demo_data = [];
    let prefixes = ['0','1','2','3', '4', '5', '6', '7','30','all']; 
    let row;
    if(triage=="all")
      row=["dd/mm/yyyy", triage, "No. of proofs", '# of Patients w/Co-Morbidities', 'Female:Male', 'Age<24', 'Age 24-50',
              'Age>50', 'New', 'Return'];
    else
      row=["dd/mm/yyyy", triage, triage+ "%", '# of Patients w/Co-Morbidities', 'Female:Male', 'Age<24', 'Age 24-50',
          'Age>50', 'New', 'Return'];
    this.demo_data.push(row);
    let element;         
    for(var i=0;i<prefixes.length;i++)
    {
      element = prefixes[i];
      row=[];
      let keys; 
      if(triage=="all")
        keys = [ element+'AllCount',element+'AllCount', element+'CoMorbidityCount', element+'SexMaleCount',
                    element+'SexFemaleCount', element+'AgeBelow24Count', element+'Age24To50Count', 
                    element+'AgeAbove50Count', element+'NewCount', element+'ReturnCount']; 
      else
        keys = [ element+'AllCount',element+'AllCountPercentage', element+'CoMorbidityCount', element+'SexMaleCount',
                  element+'SexFemaleCount', element+'AgeBelow24Count', element+'Age24To50Count', 
                  element+'AgeAbove50Count', element+'NewCount', element+'ReturnCount'];              
      if(i==7)
        row.push("Last 7 Days");
      else if(i==8)
        row.push("Last 30 Days");
      else if(i==9)
        row.push("All Entries");
      else
      {
        let date = new Date();
        date.setDate(date.getDate() - i)
        row.push(this.datePipe.transform(date,"yyyy-MM-dd"));
      }      
      for(let j=0;j<keys.length;j++)
      {  
        if(j==1){
          if(data[keys[j]]==null)
            row.push("-");
          else
            if(triage!="all")
              row.push(data[keys[j]]+"%");   
            else
              row.push(data[keys[j]]);   

        }
        else if(j==3)
          row.push(data[keys[j+1]]+":"+data[keys[j]])
        else if(j==4) 
          continue;
        else 
          row.push(data[keys[j]]);
      } 
    this.demo_data.push(row);
    }        
  }  
//end of demographics table


//Plotting bar graph for Co Morbidities

  public co_startDate;
  public co_endDate;
  dateSelected(value, picker){
    if(picker==1)
      this.co_startDate = value;
    else if(picker==2)
      this.co_endDate = value;    
    this.getComorbidities(this.co_startDate, this.co_endDate, "all");
  }
  getComorbiditiesTriage(value){    
    this.getComorbidities(this.co_startDate, this.co_endDate, value);
  }
  getComorbidities(start, end, triage){
    this.issueService
          .getComorbidities(start, end, triage)
          .subscribe(
            (data: any) => { 
              this.extractComorbidities(data);                                              
                                  
            },
            err => {
              console.log("Unable to get data");
            }
          ) 
  }
  public evaluated = [];
  extractComorbidities(data){
    var data_arr = new Array();
    this.evaluated = [];
    data.results.forEach(element => {
      data_arr.push(element.hypertension);
      data_arr.push(element.chronicPulmonaryDisease);
      data_arr.push(element.asthma);
      data_arr.push(element.tb);
      data_arr.push(element.kidneyDisease);
      data_arr.push(element.liverDisease);
      data_arr.push(element.chronicNeurologicalDisorder);
      data_arr.push(element.malignancy);
      data_arr.push(element.chronicHematologicDisease);
      data_arr.push(element.hivAids);
      data_arr.push(element.obesity);
      data_arr.push(element.diabetes);
      data_arr.push(element.rheumatologicalDisorder);
      data_arr.push(element.dementia);
      data_arr.push(element.malnutrition);
      data_arr.push(element.smokingCurrently);
      data_arr.push(element.smokingFormer);      
      data_arr.push(element.cough);
      data_arr.push(element.fever);
      data_arr.push(element.difficultyBreathing);
      data_arr.push(element.fatigue);
      data_arr.push(element.diarrhea);
      data_arr.push(element.lossOfTasteAndSmell);
      data_arr.push(element.soreThroat);
      data_arr.push(element.headache);
      data_arr.push(element.nauseaOrVomiting);
      this.evaluated.push(element.count);
      this.evaluated.push(element.none);                 
    });  
    this.barChartData = [
      { data: data_arr, label: 'Evaluations' },
      
    ];    
  }
  public barChartOptions = {
    responsive: true,
    legend: { position: 'right' },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Co-Morbidity'
        },        
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Number of people who have the Co-Morbidity'
        },
        ticks: {
          min: 0,
          precision: 0          
      }
      }],      
    } 
  };
  public barChartLabels: Label[] = ['Chronic Cardiac Disease', 'Hypertension', 'Chronic Pulmonary Disease', 
                                    'Asthma', 'TB', "Kidney Disease", 'Liver Disease', 
                                    'Chronic Neurological Disorder', 'Malignancy', 'Chronic Hematologic Disease',
                                    'HIV/AIDS', 'Obesity', 'Diabetes', 'Rheumatological Disorder', 'Dementia',
                                    'Malnutrition', 'Smoking(Currently)', 'Smoking(Former)', 'Cough',
                                    'Fever', 'Difficulty Breathing', 'Fatigue', 'Diarhhea', 'Loss of Taste or Smell',
                                    'Sore Throat', 'Headache', 'Nausea or Vomiting'];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Evaluations' },
    
  ];
  public barChartColors: Color[] = [
    { backgroundColor: 'rgb(48,94,175)' },
  ]
//end of bar chart




//to download all charts
//   downloadCanvas(event) {
//     console.log(event);
//     var anchor = event.target;    
//     anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
//     anchor.download = "Evaluations Reported Today.png";
//     anchor.href = document.getElementsByTagName('canvas')[1].toDataURL();
//     anchor.download = "Evaluations Reported Over Time.png";
//     anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
//     anchor.download = "Co Morbidities.png";
// }
  



  constructor(private issueService: ReportsService, private datePipe: DatePipe, gtag: Gtag) {
    gtag.pageview({
      page_title: 'View Reports',
      page_path: '/reports',
      
    });
   }

  ngOnInit(): void {
    this.getEvaluationsBySection();  
    setTimeout(() => {
      this.chart.chart.data.datasets[0].data = this.pieChartData;
      this.chart.chart.update();
      this.totalSec = this.pieChartData[0]+this.pieChartData[1]+this.pieChartData[2]+" ";
    }, 2000);
    this.getEvaluationsReportedOverTime("week");  
    let date = new Date();
    let today = this.datePipe.transform(date,"yyyy-MM-dd");
    let tomorrow = this.datePipe.transform(date.setDate(date.getDate() + 1),"yyyy-MM-dd");
    this.getComorbidities(today, tomorrow, "all");
    this.getDemographics("all");
  }


}
