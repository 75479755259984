import { Component } from '@angular/core';
import { GtagModule } from 'angular-gtag';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(gtag: GtagModule) {}
}
